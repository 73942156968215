import React, { useContext } from 'react'
import { CalendarContext } from "../../contexts/CalendarContext"
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { createAppointment } from '../../lib/api_service/appointments'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppStateContext } from '../../contexts/AppStateContext';
import moment from 'moment';
import Datepicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

const animatedComponents = makeAnimated();

const AppointmentForm = () => {
  const { t } = useTranslation();

  const { saveAppointment, formAppointment, setFormAppointment, resetForm, selectedEmployee, setSelectedEmployee } = useContext(CalendarContext);
  const { salonState, localeState, salonProductsState } = useContext(AppStateContext);

  const [salonProducts,] = salonProductsState;
  const [salon,,] = salonState;
  const [locale,] = localeState;

  const handlePhoneNumberChange = (value) => {
    const customer = {
      ...formAppointment.customer,
      phone_number: value || ''
    }
    setFormAppointment({ ...formAppointment, customer: customer });
  }

  const handleCustomerChange = (e) => {
    const customer = {
      ...formAppointment.customer,
      [e.target.name]: e.target.value
    }
    setFormAppointment({ ...formAppointment, customer: customer });
  }

  const handleAppointmentChange = (e) => {
    setFormAppointment({ ...formAppointment, [e.target.name]: e.target.value });
  }

  const handleProductsChange = (options) => {
    setFormAppointment({
      ...formAppointment,
      salon_products: options.map((option) => salonProducts[option.value])
    });
  }

  const handleStartDateChange = (time) => {
    const newFormAppointment = {
      ...formAppointment,
      start_date: time,
      end_date: formAppointment.end_date <= time ? time : formAppointment.end_date
    };
    setFormAppointment(newFormAppointment);
  }

  const handleEndDateChange = (time) => {
    const newFormAppointment = { ...formAppointment, end_date: time };
    setFormAppointment(newFormAppointment);
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    createAppointment(salon, formAppointment)
      .then(response => response.json())
      .then(data => {
        if (!data.errors) {
          saveAppointment(data)
          toast.success(t('appointments.create.success'))
          resetForm()
        } else {
          toast.error(t('appointments.create.error', { error: JSON.stringify(data.errors) }))
        }
      })
    document.getElementById('appointment-form').close()
  }

  const handleFormCancel = () => {
    resetForm();
  };

  const valueOk = (value) => { return value === '' };

  const validInputs = () => {
    const customer = formAppointment?.customer
    const doesNotNeedValidation = formAppointment.appointment_type === 'busy'
    const phoneNumber = customer.phone_number;
    const email = customer.email;
    const phoneNumberValid = valueOk(phoneNumber) || (phoneNumber && isValidPhoneNumber(customer.phone_number));
    const emailValid = (email && isValidEmail());
    const hasProducts = formAppointment.salon_products.length > 0
    return doesNotNeedValidation || (emailValid && phoneNumberValid && hasProducts)
  };

  const isValidEmail = () => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(formAppointment.customer.email).toLowerCase());
  };

  const handleBusy = (e) => {
    setFormAppointment({
      ...formAppointment,
      appointment_type: e.target.checked ? 'busy' : 'default'
    });
  }

  const handleEmployeeChange = ({ value }) => {
    const employee = salon.salon_employees.find(sp => sp.id === value)
    setSelectedEmployee(employee || {})
    setFormAppointment({
      ...formAppointment,
      employee: employee
    });
  }

  const availableEmployees = () => {
    const emps = salon.salon_employees?.map((employee) => {
      return {
        value: employee.id,
        label: `${employee.user.full_name}`
      };
    })
    emps.push({ value: null, label: t('indistinct')})
    return emps
  }

  return (
    <dialog id="appointment-form" className="modal" onClose={handleFormCancel}>
      <div className="modal-box flex flex-col gap-3 overflow-visible">
        <div className='flex justify-between'>
          <h1 className="font-bold text-lg mb-3">{formAppointment.appointment_type === 'busy' ? t('appointments.form.busy_title') : t('appointments.form.title')}</h1>
          <div className="form-control">
            <label className="label cursor-pointer gap-2">
              <span className="label-text">Cita</span>
              <input type="checkbox" checked={formAppointment.appointment_type === 'busy'} value={formAppointment.appointment_type} className="toggle bg-lime-500 checked:toggle-error" onChange={handleBusy}/>
              <span className="label-text">{t('busy')}</span>
            </label>
          </div>
        </div>
        {
          formAppointment.appointment_type === 'default' && <Select
            placeholder={t('appointments.form.placeholders.salon_products')}
            isMulti
            isSearchable={false}
            value={formAppointment.salon_products?.map((salon_product) => { return { value: salon_product.id, label: salon_product.product } }) || []}
            onChange={handleProductsChange}
            components={animatedComponents}
            options={Object.values(salonProducts)?.map((salonProduct) => {
              return {
                value: salonProduct.id,
                label: salonProduct.product
              };
            })}
            styles={{
              multiValue: () => {},
              control: () => {},
            }}
            classNames={{
              multiValue: () => 'badge badge-info',
              valueContainer: () => 'flex gap-x-2',
              control: () =>
                  'input input-bordered flex items-center bg-transparent h-fit ps-1',
            }}
          />
        }
        <Datepicker
          selected={formAppointment.start_date}
          onChange={handleStartDateChange}
          showTimeSelect
          minDate={new Date()}
          filterTime={(time) => time >= new Date()}
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="HH:mm aa"
          customInput={
            <label className="input input-bordered flex items-center gap-5">
              <h1 className='w-9 flex gap-2'>
                <FontAwesomeIcon icon="fa-regular fa-clock" />
                <FontAwesomeIcon icon="fa-solid fa-arrow-right-from-bracket" />
              </h1>
              <input
                type="text"
                className="grow"
                placeholder={t('appointments.form.placeholders.start_time')}
                value={moment(formAppointment.start_date).format('MMM DD - HH:mm a')}
                readOnly
              />
            </label>
          }
        />
        <Datepicker
          selected={formAppointment.end_date}
          onChange={handleEndDateChange}
          minDate={formAppointment.start_date || new Date()}
          filterTime={(time) => time >= formAppointment.start_date}
          showTimeSelect
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="HH:mm aa"
          customInput={
            <label className="input input-bordered flex items-center gap-5">
              <h1 className='w-9 flex gap-2'>
                <FontAwesomeIcon icon="fa-regular fa-clock" />
                <FontAwesomeIcon icon="fa-solid fa-arrow-right-to-bracket" />
              </h1>
              <input
                type="text"
                className="grow"
                placeholder={t('appointments.form.placeholders.end_time')}
                value={(formAppointment.end_date && moment(formAppointment.end_date).format('MMM DD - HH:mm a')) || ''}
                readOnly
              />
            </label>
          }
        />
        {
          formAppointment.appointment_type === 'default' ? <>
            <label className="input input-bordered flex items-center gap-5">
              <div className='w-9'>
                <div className="indicator">
                  <span className="indicator-item badge bg-transparent border-0 z-0">1</span>
                  <FontAwesomeIcon icon={'fas fa-user'} />
                </div>
              </div>
              <input type="text" name="first_name" className="grow" placeholder={t('appointments.form.placeholders.first_name')} value={formAppointment?.customer.first_name || ''} onChange={handleCustomerChange} autoFocus={false}/>
            </label>
            <label className="input input-bordered flex items-center gap-5">
              <div className='w-9'>
                <div className="indicator">
                  <span className="indicator-item badge bg-transparent border-0 z-0">2</span>
                  <FontAwesomeIcon icon={'fas fa-user'} />
                </div>
              </div>
              <input type="text" name="last_name" className="grow" placeholder={t('appointments.form.placeholders.last_name')} value={formAppointment?.customer.last_name || ''} onChange={handleCustomerChange} autoFocus={false}/>
            </label>
            <label className="input input-bordered flex items-center gap-5">
              <div className='w-9'>
                <FontAwesomeIcon icon="fa-solid fa-envelope" />
              </div>
              <input type="email" name='email' className="grow" placeholder={t('appointments.form.placeholders.email')} value={formAppointment?.customer.email || ''} onChange={handleCustomerChange}/>
            </label>
            <PhoneInput
              placeholder={t('appointments.form.placeholders.phone_number')}
              value={formAppointment?.customer.phone_number || ''}
              onChange={handlePhoneNumberChange}
              defaultCountry={locale?.country_code}
              className='input input-bordered gap-4'
            />
          </> : <>
          <label className="form-control">
            <textarea
              name='description'
              className="textarea textarea-bordered h-24"
              placeholder={t('appointments.form.placeholders.description')}
              onChange={handleAppointmentChange}
            ></textarea>
          </label>
          </>
        }
        <Select
          placeholder={t('professional')}
          isSearchable={false}
          value={selectedEmployee.id && { value: selectedEmployee?.id, label: `${selectedEmployee.user?.first_name} ${selectedEmployee.user?.last_name}` }}
          onChange={handleEmployeeChange}
          options={availableEmployees()}
          styles={{
            multiValue: () => {},
            control: () => {},
          }}
          classNames={{
            multiValue: () => 'badge badge-info',
            valueContainer: () => 'flex gap-x-2',
            control: () =>
                'input input-bordered flex items-center bg-transparent h-fit ps-1',
          }}
        />
        <div className='flex justify-between'>
          <button className="btn btn-neutral btn-sm mt-3" onClick={() => {document.getElementById('appointment-form').close()}}>{t('cancel')}</button>
          <button className="btn btn-warning btn-sm mt-3" onClick={handleSubmit} disabled={!validInputs()}>{t('save')}</button>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  )
}

export default AppointmentForm
