import React, { useContext } from 'react'
import { CalendarContext } from '../../../../contexts/CalendarContext';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AppointmentModal = () => {
  const { t } = useTranslation();
  const { selectedAppointment, handleAppointmentDestroy, handleDelay } = useContext(CalendarContext);
  return (
    <dialog id="appointment-modal" className="modal">
      {
        selectedAppointment.id && <div className="modal-box">
          <div className="flex gap-1">
            <h3 className="flex-1 font-bold text-lg">{ selectedAppointment.customer ? selectedAppointment.customer.full_name : t('busy') }</h3>
            {
              selectedAppointment.appointment_type === 'default' && <>
                <button className="btn btn-sm btn-outline" onClick={handleDelay}>
                  <div className='flex capitalize'>
                    {t('delay')}
                  </div>
                </button>
              </>
            }
            <button className="btn btn-sm btn-outline btn-error" onClick={handleAppointmentDestroy}>
              {t('cancel')}
            </button>
          </div>
          {
            selectedAppointment.customer && <>
              <h3 className="text-sm">{selectedAppointment.customer.email}</h3>
              <h3 className="text-sm">{selectedAppointment.customer.phone_number}</h3>
            </>
          }
          <div className="divider my-2"></div>
          { selectedAppointment.description && <div className='mb-3'>{selectedAppointment.description}</div> }
          <div className='flex justify-between'>
            <p className="">
              {moment(selectedAppointment.start_date).format('ddd - DD MMM, YYYY')}
            </p>
            <div>
              {
                selectedAppointment.payment_method && <div
                                                        className="tooltip"
                                                        data-tip={t(`paymentMethods.${selectedAppointment.payment_method.name}`)}
                                                      >
                                                        <FontAwesomeIcon icon={t(`paymentMethods.icons.${selectedAppointment.payment_method.name}`)} />
                                                      </div>
              }
              {
                selectedAppointment.appointment_type === 'default' && <>
                  <strong className='mx-2'>{t('paid')}:</strong>
                  { selectedAppointment.paid ? t('yes') : t('no')}
                </>
              }
            </div>
          </div>
          <p className="py-4">
            {moment(selectedAppointment.start_date).format('HH:mm')} - {moment(selectedAppointment.end_date).format('HH:mm')}
          </p>
          <div className='flex justify-between items-center'>
            <div className="flex-1 flex gap-2">
              {
                selectedAppointment?.salon_products?.map(salon_product => {
                  return <div key={salon_product.id} className="badge badge-info">{salon_product.product}</div>;
                })
              }
            </div>
            {
              selectedAppointment.google_meet_url && <Link to={selectedAppointment.google_meet_url} target='_blank' title='google meet link' className='flex items-center gap-2'>
                <img src={`${process.env.PUBLIC_URL}/google_meet_logo.png`} width='30' alt='' />
              </Link>
            }
          </div>
          {
            selectedAppointment.employee && <>
              <h3 className="text-sm mt-3">{t('professional')} - {selectedAppointment.employee.user.full_name}</h3>
            </>
          }
        </div>
      }
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  )
}

export default AppointmentModal